@import 'Colors';

@import 'Header';
@import 'About';
@import 'AboutMe';
@import 'Skills';
@import 'Footer';
@import 'Projects';
@import 'Contact';


html {
  scroll-behavior: smooth;
}

.App{
  background-color: $white;
  transition: all .2s ease-in-out;
  // width: 100vw;
  overflow: hidden;
}


.App.dark{
  background-color: $black;

  .text-black{
    color: $white
  
  }
}