/* src/components/Footer.css */

.footer-section {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo img {
  width: 150px;
  margin-bottom: 20px;
}

.footer-nav {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-nav a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s ease-in-out;
}

.footer-nav a:hover {
  color: #ff7f00; /* Adjust to your theme color */
}

.footer-social {
  display: flex;
  gap: 15px;
}

.footer-social a {
  color: #333;
  font-size: 24px;
  transition: color 0.2s ease-in-out;
}

.footer-social a:hover {
  color: #ff7f00; /* Adjust to your theme color */
}

.dark {
  .footer-section {
    background-color: $pitchBlck;
  }
  .footer-social a {
    color: $white;
  }
  .footer-nav a {
    color: $white;
  }
  .footer-nav a:hover {
    color: $orange;
  }
  .footer-social a:hover {
    color: $orange;
  }
}

/* Responsive Styling */
@media (max-width: 768px) {
  .footer-nav {
    flex-direction: column;
    gap: 10px;
  }
}
